<template>
  <div class="login-container">
    <div class="container">
      <div class="d-flex align-items-center mb-3">
        <img v-if="sidebarLogoUrl" :src="sidebarLogoUrl" alt="Sidebar Logo" class="logo"/>
        <img v-else src="@/assets/images/icon.svg" alt="RegAlytics Logo" width="40">
        <h3 class="ms-3">{{ sidebarName }} Login</h3>
      </div>

      <div class="login-card">
        <div class="row align-items-center">
          <div class="d-none d-md-block col-lg-4 login-banner-image"></div>
          <div class="col-lg-8 mx-auto login-form">

            <div v-if="isLoading" class="text-center">
              <spinner/>
            </div>

            <div class="login-form" v-else>
              <form @submit.prevent="handleLogin">
                <div class="mb-3">
                  <label class="form-label">Email</label>
                  <input v-model="userEmail" class="form-control" name="userEmail" required type="email"/>
                </div>
                <div class="mb-3">
                  <label class="form-label">Password</label>
                  <input v-model="userPassword" class="form-control" name="userPassword" required @click="userPassword = ''" type="password"/>
                </div>
                <div class="d-grid mb-2">
                  <button class="btn btn-green btn-block py-2" type="submit">Login</button>
                </div>
                <div class="small d-flex justify-content-between">
                  <router-link class="float-right" to="/reset-password">I forgot my password.</router-link>
                  <router-link v-if="showSignupLink" class="float-right" to="/signup">I need an account.</router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Login",
  components: {
    Spinner
  },

  data() {
    return {
      userEmail: '',
      userPassword: '',
      submitted: false
    }
  },

  computed: {
    ...mapGetters('accounts', ['isLoading']),
    sidebarName() {
      return localStorage.getItem('sidebar_name') || 'RegAlytics®';
    },

    // Computed property for sidebar logo URL
    sidebarLogoUrl() {
      return localStorage.getItem('sidebar_logo_url') ;
    },
    // Computed property to determine if signup link should be shown
    showSignupLink() {
      const hostname = window.location.hostname;
      
      // Allow signup for localhost and IP addresses
      if (['localhost', '127.0.0.1'].includes(hostname) || 
          /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(hostname)) {
        return true;
      }

      // Check if it's a subdomain of subdomain
      const isSubdomainOfSubdomain = /^(?!www\.)([a-z0-9-]+\.){2,}[a-z0-9-]+\.[a-z]+$/.test(hostname);
      
      // Show signup link only if it's NOT a subdomain of subdomain
      return !isSubdomainOfSubdomain;
    }
  },

  methods: {
    ...mapActions('accounts', ['login']),
    handleLogin() {
      this.submitted = true;
      const email = this.userEmail.toLowerCase();
      const password = this.userPassword;

      if (email && password) {
        this.login({email, password});
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  position: fixed;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.login-form {
  padding: 2rem 2rem;
}
</style>